/**
 * Initializes the Datadog Real User Monitoring (RUM) in the client-side.
 * This file should be included if using App Router to ensure it runs on the client.
 * Source: https://docs.datadoghq.com/real_user_monitoring/guide/monitor-your-nextjs-app-with-rum/?tab=npm#instrument-your-application
 */

'use client'

import { datadogRum } from '@datadog/browser-rum-slim'
import { SERVICE_NAME } from 'app/constants'

datadogRum.init({
  applicationId: '0226d486-85ab-49ff-a55f-d69e4fa30e1d',
  clientToken: 'pub5bb52c2f0e4e9a7b2cf0776a0c6c0c5f',
  site: 'us5.datadoghq.com',
  service: SERVICE_NAME,
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  env: process.env.NEXT_PUBLIC_VERCEL_ENV,
  sessionSampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  usePartitionedCrossSiteSessionCookie: true
})

export default function Datadog() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null
}
